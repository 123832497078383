.coronavirus {
  &_img {
    display: none;
  }
}
@media(min-width:640px){
  .coronavirus {
    &_img {
      display: inline-block;
    }
  }
}
.btn-lead {
  text-transform: uppercase;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
}
@media(min-width:992px){
  .btn-lead {
    font-size: 16px;
  }
}