.bottom-form {
  
    &__send-block {
      text-align: center;
      .order_button {
           button {
          padding: 5px 40px;
        }
      }
    }
}
@media(min-width:640px){
  .bottom-form {
      &__grid {
      display: grid;
      grid-template-areas: 
        'fio email'
        'tel cls'
        'send_block send_block';
      column-gap:10px;
      & > *:nth-of-type(1){
        grid-area: fio;
      }

      & > *:nth-of-type(2){
        grid-area: tel;
      }

      & > *:nth-of-type(3){
        grid-area: email;
      }

      & > *:nth-of-type(4){
        grid-area: cls;
      }

      & > *:nth-of-type(5){
        grid-area: send_block;
        
      }

    }

  } 
}


@media(min-width:992px){
  .bottom-form {
    &__grid {
      display: grid;
      grid-template-areas: 
        'fio email send_block'
        'tel cls send_block';

      
    }

    &__send-block {
      .order_button,
      .order_button button {
        max-width: 100%;
        width: 100%;
        
      }
    }
    & &__agreement {
      max-width: 250px;
      margin:auto;
    }
  }
}

// @media(min-width:1200px){
//   .bottom-form-grid {
//     display: grid;
//     grid-template-areas: 
//       'fio email tel cls'
//       'send_block send_block send_block send_block';

//   }
// }