
.panel {
  &-green {
    border: 1px solid @c-green;
  }
  &-green &-heading{
    border-bottom: 0px; 
    background: @c-green;

  }

  &-green &-title {
    text-align: center;
    margin: 0px;
    color: white;
    text-transform: uppercase;
    font-size: 18px; 
  }
}
@media(min-width:640px){
  .dod-online {
    &__order {
      float: right;
      width: 300px;
      margin-left: 15px;
    }
  }
}

@media(min-width:992px){
  .dod-online {
    &__order {
      width: 33%;

    }
  }
}
.index-dod-online {
  &__video-wrap {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &__video {
    width: 100%;
  }
  &__text {
    text-align: center;
    font-size: 16px;
  }
  &__lead {
    font-size: 20px;
  }
}
@media(min-width:380px){
  .index-dod-online {
    &__video {
      min-height: 250px;
    }
  }
}
@media(min-width:640px){
  
  .index-dod-online {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    &__text {
      font-size: 18px;
    }

    & > div {
      flex:0 0 48%;
    }
    &__video-wrap {
      margin-top: 0;
      margin-bottom: 0;
    }

    &__video {
      width: 100%;
      //height: 100%;
      display: block;
      // min-height: auto;
    }
  }

}
@media(min-width:992px){
  .index-dod-online {
    &__text {
      padding-top: 25px;
      padding-bottom: 25px;
      font-size: 25px;
    }
    &__lead {
      font-size: 35px;
    }
  }
}